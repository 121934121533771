import "./App.css"
import AnimatedBackground from "./components/animated-background.tsx"
import Header from "./components/header.tsx"
import Footer from "./components/footer.tsx"
import React from "react"
import { Outlet } from "@tanstack/react-router"

function App() {
	return (
		<div className="relative min-h-screen bg-surface1 w-full overflow-x-hidden">
			<AnimatedBackground>
				<div className="relative z-10">
					<Header />
					<Outlet />
					<Footer />
				</div>
			</AnimatedBackground>
		</div>
	)
}

export default App
