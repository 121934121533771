import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Button from "./button";
import { requestAccess } from "../api/request";

const CTASection = () => {
  const titleRef = useRef(null);
  const [displayText, setDisplayText] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isVisible) {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 }
    );

    if (titleRef.current) {
      observer.observe(titleRef.current);
    }

    return () => observer.disconnect();
  }, [isVisible]);

  useEffect(() => {
    const finalText = "Ready to bring your app idea to life?";
    let blinkCount = 0;
    let currentIndex = 0;
    let blinkInterval: NodeJS.Timeout;
    let typingInterval: NodeJS.Timeout;

    if (isVisible) {
      // Reset text when section becomes visible
      setDisplayText("");

      // Initial cursor blink
      blinkInterval = setInterval(() => {
        blinkCount++;
        if (blinkCount >= 4) {
          // 2 complete blinks (4 state changes)
          clearInterval(blinkInterval);

          // Start typing
          typingInterval = setInterval(() => {
            if (currentIndex < finalText.length) {
              setDisplayText(finalText.slice(0, currentIndex + 1));
              currentIndex++;
            } else {
              clearInterval(typingInterval);
              if (titleRef.current) {
                titleRef.current.classList.add("hide-cursor");
              }
            }
          }, 50); // Adjust typing speed here
        }
      }, 350); // Adjust blink speed here
    }

    // Cleanup intervals and reset when section is not visible
    return () => {
      clearInterval(blinkInterval);
      clearInterval(typingInterval);
      if (!isVisible) {
        setDisplayText("");
        if (titleRef.current) {
          titleRef.current.classList.remove("hide-cursor");
        }
      }
    };
  }, [isVisible]);

  const [showModal, setShowModal] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(true);
  const [modalContent, setModalContent] = useState<{
    title: string;
    message: string;
    type: "success" | "error";
  }>({
    title: "",
    message: "",
    type: "success",
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log("Form submission started"); // Debug log

    try {
      setShowSubmitButton(false);
      await requestAccess(formData);

      // Clear form after successful submission
      setFormData({
        name: "",
        email: "",
        message: "",
      });

      // Show success modal
      setModalContent({
        title: "Message Sent!",
        message: "Thank you for your message! We will get back to you soon.",
        type: "success",
      });
      setShowModal(true);
    } catch (error) {
      console.error("Error submitting form:", error);
      setModalContent({
        title: "Oops!",
        message:
          "Sorry, there was an error submitting your message. Please try again later.",
        type: "error",
      });
      setShowModal(true);
    } finally {
      setShowSubmitButton(true);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="fixed inset-0 bg-surface1/80 backdrop-blur-sm z-50"
              onClick={() => setShowModal(false)}
            />
            <motion.div
              initial={{ opacity: 0, scale: 0.9, y: 20 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.9, y: 20 }}
              transition={{ type: "spring", duration: 0.5 }}
              className="fixed inset-0 flex items-center justify-center z-50"
            >
              <div className="w-[90%] max-w-md">
                <div className="bg-surface2/95 backdrop-blur-sm p-6 rounded-xl border border-outline shadow-xl w-full">
                  <div
                    className={`text-2xl font-bold mb-4 ${modalContent.type === "success" ? "text-clickable" : "text-error"}`}
                  >
                    {modalContent.title}
                  </div>
                  <p className="text-base mb-6">{modalContent.message}</p>
                  <Button
                    onClick={() => setShowModal(false)}
                    className="w-full"
                    variant={
                      modalContent.type === "success" ? "default" : "galaxy"
                    }
                  >
                    Close
                  </Button>
                </div>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
      <div
        id="cta"
        className="bg-transparent text-heading py-20 2xl:py-28 px-4 2xl:px-8 scroll-mt-20"
      >
        <div className="max-w-4xl 2xl:max-w-6xl mx-auto text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <h2
              className="text-4xl font-bold mb-6 typing-cursor"
              ref={titleRef}
            >
              {displayText}
            </h2>
            <p className="text-xl text-base text-lg mb-12">
              Try JigJoy now and build your first app in minutes.
            </p>
          </motion.div>

          <motion.form
            onSubmit={handleSubmit}
            className="space-y-6 max-w-xl mx-auto"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            <div className="flex flex-col space-y-2">
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Your Name"
                className="px-4 py-3 bg-surface2/30 border border-outline rounded-lg focus:outline-none focus:border-accent text-heading placeholder-base backdrop-blur-sm"
                required
              />
            </div>

            <div className="flex flex-col space-y-2">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Your Email"
                className="px-4 py-3 bg-surface2/30 border border-outline rounded-lg focus:outline-none focus:border-accent text-heading placeholder-base backdrop-blur-sm"
                required
              />
            </div>

            <div className="flex flex-col space-y-2">
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Tell us what you'd like to build"
                rows={4}
                className="px-4 py-3 bg-surface2/30 border border-outline rounded-lg focus:outline-none focus:border-accent text-heading placeholder-base backdrop-blur-sm resize-none"
                required
              />
            </div>

            {showSubmitButton && (
              <Button type="submit" className="w-full px-8 py-4">
                Request Access
              </Button>
            )}

            {!showSubmitButton && (
              <div className="w-full px-8 py-4">
                Request sending in progress...
              </div>
            )}
          </motion.form>
        </div>
      </div>
    </>
  );
};

export default CTASection;
