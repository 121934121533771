import React from "react"
import { motion } from "framer-motion"
import DeploymentAnimation from "./animations/deployment-animation"
import BuildingAnimation from "./animations/building-animation"
import CustomizationAnimation from "./animations/customization-animation"
import SharingAnimation from "./animations/sharing-animation"

const features = [
	{
		title: "Chat to Build",
		description:
			"Describe your app idea in simple words. We'll build it for you - like magic.",
		badge: "How It Works",
		isBuilding: true,
	},
	{
		title: "Easy Changes",
		description:
			"Want to change something? Just ask. Colors, layout, features - we'll update it instantly.",
		badge: "Customization",
		isCustomizing: true,
	},
	{
		title: "One-Click Launch",
		description:
			"Your app goes live instantly. No technical setup needed.",
		image: "https://jigjoy-website.s3.eu-west-1.amazonaws.com/demopic.png",
		badge: "Deploy",
	},
	{
		title: "Share Instantly",
		description:
			"Get your own web address. Share your app with anyone, anywhere.",
		badge: "Share",
		isSharing: true,
	},
]

const FeaturesSection = () => {
	return (
		<div id="features-section" className="bg-transparent text-white py-16 2xl:py-24 px-4 2xl:px-8 2xl:scale-110 scroll-mt-20">
			<h2 className="text-6xl md:text-7xl 2xl:text-8xl font-bold text-center mb-16 text-heading">Features</h2>
			<div className="max-w-7xl 2xl:max-w-8xl mx-auto">
				{features.map((feature, index) => (
					<div
						key={index}
						className={`flex flex-col ${
							index % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
						} items-center gap-12 mb-32 last:mb-0`}
					>
						{/* Image */}
						<motion.div
							className="w-full md:w-1/2"
							initial={{ opacity: 0, x: index % 2 === 0 ? -50 : 50 }}
							whileInView={{ opacity: 1, x: 0 }}
							viewport={{ once: true, margin: "-100px" }}
							transition={{
								duration: 1.2,
								ease: "easeOut",
							}}
						>
							{feature.isBuilding ? (
								<BuildingAnimation />
							) : feature.isCustomizing ? (
								<CustomizationAnimation />
							) : feature.badge === "Deploy" ? (
								<DeploymentAnimation />
							) : feature.isSharing ? (
								<SharingAnimation />
							) : (
								<img
									src={feature.image}
									alt={feature.title}
									className="rounded-lg w-full h-auto max-h-[500px] object-contain shadow-2xl"
								/>
							)}
						</motion.div>

						{/* Content */}
						<motion.div
							className="w-full md:w-1/2 space-y-6"
							initial={{ opacity: 0, x: index % 2 === 0 ? 50 : -50 }}
							whileInView={{ opacity: 1, x: 0 }}
							viewport={{ once: true, margin: "-100px" }}
							transition={{
								duration: 1.2,
								delay: 0.2,
								ease: "easeOut",
							}}
						>
							<span className="inline-block px-3 py-1 text-xs font-semibold text-white bg-surface3 rounded-full">
								{feature.badge}
							</span>
							<h2 className="text-4xl font-bold text-heading">{feature.title}</h2>
							<p			className="text-base text-xl leading-relaxed">{feature.description}</p>
						</motion.div>
					</div>
				))}
			</div>
		</div>
	)
}

export default FeaturesSection
