export const API_HOST = process.env.REACT_APP_API

interface AccessRequest {
	id?: string
	name: string
	message: string
	email: string
}

export async function requestAccess(request: AccessRequest): Promise<any> {
	const options = {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(request),
	}

	const res: any = await fetch(`${API_HOST}/user-acquisition/request-access`, options)
	return await res.json()
}
