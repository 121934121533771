import React from "react";
import HeroSection from "../components/hero-section";
import HowItWorks from "../components/how-it-works";
import CardSection from "../components/card-section";
import FeaturesSection from "../components/features-section";
import ProjectsShowcase from "../components/projects-showcase";
import CTASection from "../components/cta-section";
import FAQ from "../components/faq";

const Home = () => {
  React.useEffect(() => {
    // Check if we have a stored scroll target
    const scrollTarget = sessionStorage.getItem('scrollTarget')
    if (scrollTarget) {
      sessionStorage.removeItem('scrollTarget')
      setTimeout(() => {
        const element = document.querySelector(scrollTarget)
        element?.scrollIntoView({ behavior: "smooth" })
      }, 100)
    }
  }, [])

  return (
    <>
      <HeroSection />
      <HowItWorks />
      <FeaturesSection />
      <CardSection />
      <ProjectsShowcase />
      <FAQ />
      <CTASection />
    </>
  );
};

export default Home;
