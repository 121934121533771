import React, { useState, useEffect } from "react"
import { motion, AnimatePresence } from "framer-motion"
import Button from "../button"

const DeploymentAnimation = () => {
	const [step, setStep] = useState(0)
	const totalSteps = 4
	const [isButtonClicked, setIsButtonClicked] = useState(false)
	const [isInView, setIsInView] = useState(false)

	useEffect(() => {
		let timer: NodeJS.Timeout
		if (isInView) {
			timer = setInterval(() => {
				setStep((prev) => {
					if (prev === 0) {
						setIsButtonClicked(true)
					} else if (prev === totalSteps - 1) {
						setIsButtonClicked(false)
					}
					return (prev + 1) % totalSteps
				})
			}, 1500)
		}
		return () => clearInterval(timer)
	}, [isInView])

	const containerVariants = {
		hidden: { opacity: 0 },
		visible: { opacity: 1 },
	}

	return (
		<motion.div
			className="bg-surface2/30 backdrop-blur-sm p-8 rounded-xl relative overflow-hidden cursor-pointer h-[400px] flex flex-col items-center"
			variants={containerVariants}
			initial="hidden"
			whileInView="visible"
			viewport={{ once: true, amount: 0.3 }}
			onViewportEnter={() => setIsInView(true)}
			onViewportLeave={() => setIsInView(false)}
			whileHover={{ scale: 1.02 }}
			transition={{ duration: 0.3 }}
		>
			{/* Deploy Button and Cursor Container */}
			<div className="relative pt-12 mb-16">
				{/* Cursor */}
				<motion.div
					className="absolute pointer-events-none z-10"
					initial={{ x: -200, opacity: 0 }}
					animate={isInView && {
						x: isButtonClicked ? 0 : -200,
						opacity: 1,
						scale: isButtonClicked ? 0.9 : 1,
					}}
					transition={{
						duration: 0.8,
						scale: { duration: 0.1 },
					}}
					style={{ left: "45%", top: "50%", transform: "translate(-50%, -50%)" }}
				>
					<svg width="32" height="32" viewBox="0 0 24 24" className="drop-shadow-lg">
						<path d="M7 2l12 11.2-5.8.5 3.3 7.3-2.2 1-3.2-7-4.1 4z" fill="#90E86F" stroke="#151515" strokeWidth="1" />
					</svg>
				</motion.div>

				{/* Deploy Button */}
				<motion.div className="relative">
					<Button
						className="text-xl px-12 py-6"
						animate={isButtonClicked ? { scale: 0.92 } : { scale: 1 }}
						transition={{
							type: "spring",
							stiffness: 400,
							damping: 10,
						}}
					>
						Deploy 🚀
					</Button>
				</motion.div>
			</div>

			{/* Deployment Progress */}
			<AnimatePresence mode="wait">
				{step === 2 && (
					<motion.div
						key="deploying"
						initial={{ opacity: 0, scale: 0.8 }}
						animate={{ opacity: 1, scale: 1 }}
						exit={{ opacity: 0, scale: 0.8 }}
						className="text-center"
					>
						<div className="flex items-center justify-center gap-2">
							<motion.div
								animate={{ rotate: 360 }}
								transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
								className="w-5 h-5 border-2 border-accent border-t-transparent rounded-full"
							/>
							<span className="text-base">Deploying...</span>
						</div>
					</motion.div>
				)}
				{step === 3 && (
					<motion.div
						key="deployed"
						initial={{ opacity: 0, scale: 0.8 }}
						animate={{ opacity: 1, scale: 1 }}
						exit={{ opacity: 0, scale: 0.8 }}
						className="text-center"
					>
						<div className="flex items-center justify-center gap-2 text-clickable">
							<svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
							</svg>
							<span>Deployed Successfully!</span>
						</div>
						<motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} className="mt-4">
							<div className="bg-surface3 rounded-lg p-2 inline-block">
								<span className="text-accent">https://</span>
								<span className="text-base">myapp.jigjoy.dev</span>
							</div>
						</motion.div>
					</motion.div>
				)}
			</AnimatePresence>
		</motion.div>
	)
}

export default DeploymentAnimation
