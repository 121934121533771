import React from "react"
import KittyIcon from "../icons/kitty-icon"

const Footer = () => {
	return (
		<footer className="bg-transparent text-heading py-12 px-4 border-t border-outline/20">
			<div className="max-w-7xl mx-auto">
				<div className="text-center mb-16">
					<div className="flex flex-col items-center justify-center">
						<div className="mb-2">
							<KittyIcon className="w-48 h-48 text-accent fill-current" />
						</div>
						<h3 className="text-8xl md:text-9xl lg:text-10xl font-bold mb-4">
							<span className="galaxy-text">JigJoy</span>
						</h3>
					</div>
					<p className="text-base/70 mt-4">AI-Powered App Builder</p>
				</div>
				<div className="text-center mb-12">
					<p className="text-base/70">JIG JOY D.O.O.</p>
					<p className="text-base/70">Novi Sad, Fruškogorska 1</p>
					<p className="text-base/70">Science and Technology Park</p>
				</div>
				<div className="mt-8 pt-8 border-t border-outline/20 text-center">
					<div className="mb-6 space-x-6">
						<a href="/privacy" className="text-sm text-clickable hover:text-hover transition-colors">
							Privacy Policy
						</a>
						<a href="/terms-of-use" className="text-sm text-clickable hover:text-hover transition-colors">
							Terms of Use
						</a>
					</div>
					<p className="text-sm text-base/50">&copy; {new Date().getFullYear()} JigJoy. All rights reserved.</p>
				</div>
			</div>
		</footer>
	)
}

export default Footer
