import React, { useRef } from "react"
import { motion, useInView } from "framer-motion"

const cards = [
	{
		id: 1,
		title: "Tell Us Your Idea",
		subtitle: "Chat with us like a friend. No tech talk needed.",
		badge: "Easy Start",
		link: "#",
	},
	{
		id: 2,
		title: "Watch It Build",
		subtitle: "See your app come to life in real-time.",
		badge: "Magic",
		link: "#",
	},
	{
		id: 3,
		title: "Share It",
		subtitle: "One click to go live. Share with anyone.",
		badge: "Launch",
		link: "#",
	},
]

const CardSection = () => {
	const containerRef = useRef(null)
	const isInView = useInView(containerRef, { once: true, margin: "-100px" })

	return (
		<div className="bg-transparent text-heading py-16 2xl:py-24 px-4 2xl:px-8 2xl:scale-110">
			<div className="max-w-7xl 2xl:max-w-8xl mx-auto">
				<div ref={containerRef} className="grid grid-cols-1 md:grid-cols-3 gap-8">
					{cards.map((card, index) => (
						<motion.div
							key={card.id}
							className="bg-gradient-to-r from-surface2/20 to-black/20 backdrop-blur-sm p-6 rounded-lg shadow-lg"
							initial={{ opacity: 0, y: 50 }}
							animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
							transition={{
								duration: 0.5,
								delay: index * 0.4,
								ease: [0.25, 0.1, 0.25, 1],
							}}
						>
							<div className="mb-4">
								<span className="inline-block px-3 py-1 text-xs font-semibold text-base bg-surface3 rounded-full">
									{card.badge}
								</span>
							</div>
							<h3 className="text-2xl font-bold mb-2">{card.title}</h3>
							<p className="text-xl text-base">{card.subtitle}</p>
						</motion.div>
					))}
				</div>
			</div>
		</div>
	)
}

export default CardSection
