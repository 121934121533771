import React from "react"
import { motion } from "framer-motion"
import Button from "./button"
import KittyIcon from "../icons/kitty-icon"

const HeroSection = () => {
	return (
		<div className="text-heading min-h-[40vh] pt-16 bg-transparent">
			<div className="flex flex-col items-center text-center px-4 py-10 md:py-16 2xl:py-24">
				<motion.h1
					className="text-5xl md:text-7xl 2xl:text-8xl font-bold leading-tight"
					initial={{ opacity: 0, y: 50 }}
					whileInView={{ opacity: 1, y: 0 }}
					viewport={{ once: true }}
					transition={{
						duration: 1.2,
						ease: "easeOut",
					}}
				>
					Turn Text Into
					<br />
					<motion.span
						className="galaxy-text p-2 pb-3 inline-block leading-tight"
						whileHover={{
							scale: 1.05,
							transition: { duration: 0.2 },
						}}
					>
						Working Apps
					</motion.span>
				</motion.h1>

				<motion.p
					className="pt-4 text-xl text-base"
					initial={{ opacity: 0, y: 50 }}
					whileInView={{ opacity: 1, y: 0 }}
					viewport={{ once: true }}
					transition={{
						duration: 1.2,
						delay: 0.4,
						ease: "easeOut",
					}}
				>
					Tell us what you want to build.
					<br />
					Our AI turns your words into a real app.
				</motion.p>
				<motion.div
					className="flex space-x-4 mt-8"
					initial={{ opacity: 0, y: 50 }}
					whileInView={{ opacity: 1, y: 0 }}
					viewport={{ once: true }}
					transition={{
						duration: 1.2,
						delay: 0.8,
						ease: "easeOut",
					}}
				>
					<Button
						href="#cta"
						variant="galaxy"
						onClick={(e) => {
							e.preventDefault()
							const ctaSection = document.querySelector("#cta")
							if (ctaSection) {
								ctaSection.scrollIntoView({ behavior: "smooth" })
							}
						}}
					>
						Start Building
					</Button>
				</motion.div>
			</div>
		</div>
	)
}

export default HeroSection
