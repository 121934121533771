import React, { useState, useEffect } from "react"
import { motion, AnimatePresence } from "framer-motion"

const SharingAnimation = () => {
	const [step, setStep] = useState(0)
	const totalSteps = 4
	const [text, setText] = useState("")
	const promptText = "Share my app with everyone"
	const [isInView, setIsInView] = useState(false)

	const aiThoughts = [
		"Preparing your app for sharing...",
		"Generating your custom domain...",
		"Setting up analytics dashboard...",
		"Your app is now live! 🎉",
	]

	useEffect(() => {
		let timer: NodeJS.Timeout
		if (isInView) {
			timer = setInterval(() => {
				setStep((prev) => (prev + 1) % totalSteps)
				setText("")
			}, 3000)
		}
		return () => clearInterval(timer)
	}, [isInView])

	useEffect(() => {
		if (step === 0 && isInView) {
			let currentText = ""
			const typeInterval = setInterval(() => {
				if (currentText.length < promptText.length) {
					currentText = promptText.slice(0, currentText.length + 1)
					setText(currentText)
				} else {
					clearInterval(typeInterval)
				}
			}, 50)
			return () => clearInterval(typeInterval)
		}
	}, [step, isInView])

	const statsVariants = {
		hidden: { opacity: 0, scale: 0.8 },
		visible: (i) => ({
			opacity: 1,
			scale: 1,
			transition: {
				delay: i * 0.1,
				type: "spring",
				stiffness: 400,
				damping: 15,
			},
		}),
	}

	const domainVariants = {
		hidden: { opacity: 0, y: 20 },
		visible: {
			opacity: 1,
			y: 0,
			transition: {
				type: "spring",
				stiffness: 300,
				damping: 20,
			},
		},
	}

	const pulseVariant = {
		initial: { scale: 1 },
		pulse: {
			scale: [1, 1.05, 1],
			transition: {
				duration: 1.5,
				repeat: Infinity,
				ease: "easeInOut",
			},
		},
	}

	return (
		<motion.div
			className="bg-surface2/30 backdrop-blur-sm p-8 rounded-xl relative overflow-hidden h-[400px]"
			initial={{ opacity: 0 }}
			whileInView={{ opacity: 1 }}
			viewport={{ once: true, margin: "-100px" }}
			onViewportEnter={() => setIsInView(true)}
			onViewportLeave={() => setIsInView(false)}
			transition={{ duration: 0.3 }}
		>
			{/* Chat Interface */}
			<div className="bg-surface1 rounded-lg p-4 mb-4">
				<div className="flex gap-2 mb-3">
					<div className="w-3 h-3 rounded-full bg-red-500"></div>
					<div className="w-3 h-3 rounded-full bg-yellow-500"></div>
					<div className="w-3 h-3 rounded-full bg-green-500"></div>
				</div>
				<div className="flex items-center gap-2">
					<span className="text-accent"></span>
					<div className="font-mono text-sm text-base">
						{text}
						{step === 0 && <span className="animate-pulse">|</span>}
					</div>
				</div>
				<AnimatePresence mode="wait">
					{step > 0 && (
						<motion.div
							initial={{ opacity: 0, y: 10 }}
							animate={{ opacity: 1, y: 0 }}
							exit={{ opacity: 0, y: -10 }}
							className="mt-2 text-xs text-base/70 italic"
						>
							<span className="mr-2">🤖</span>
							{aiThoughts[step] || aiThoughts[aiThoughts.length - 1]}
						</motion.div>
					)}
				</AnimatePresence>
			</div>

			{/* Sharing Preview */}
			<div className="relative h-48 bg-surface1/50 rounded-lg overflow-hidden p-6">
				<AnimatePresence>
					{step >= 1 && (
						<motion.div variants={domainVariants} initial="hidden" animate="visible" className="text-center mb-6">
							<motion.div
								className="inline-block bg-surface2 rounded-full px-4 py-2 text-accent"
								variants={pulseVariant}
								animate={step >= 2 ? "pulse" : "initial"}
							>
								myapp.jigjoy.dev
							</motion.div>
						</motion.div>
					)}

					{step >= 2 && (
						<div className="grid grid-cols-3 gap-4">
							{[
								{ label: "Visitors", value: "124", icon: "👥" },
								{ label: "Active Now", value: "12", icon: "⚡" },
								{ label: "Shares", value: "28", icon: "🔗" },
							].map((stat, i) => (
								<motion.div
									key={stat.label}
									custom={i}
									variants={statsVariants}
									initial="hidden"
									animate="visible"
									className="bg-surface2/50 rounded-lg p-3 text-center"
								>
									<div className="text-2xl mb-1">{stat.icon}</div>
									<motion.div
										className="text-xl font-bold text-accent"
										animate={
											step >= 3
												? {
														scale: [1, 1.2, 1],
														transition: {
															duration: 0.5,
															delay: i * 0.1,
														},
												  }
												: {}
										}
									>
										{stat.value}
									</motion.div>
									<div className="text-xs text-base mt-1">{stat.label}</div>
								</motion.div>
							))}
						</div>
					)}
				</AnimatePresence>
			</div>
		</motion.div>
	)
}

export default SharingAnimation
