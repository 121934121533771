import React from "react"

const Terms = () => {
	return (
		<div className="min-h-screen bg-surface1 pt-24 px-4">
			<div className="max-w-4xl mx-auto pb-24">
				<h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-12 text-center">
					<span className="galaxy-text">Terms of Use</span>
				</h1>

				<div className="prose prose-invert max-w-none">
					<section className="mb-8">
						<p className="text-base mb-4">Effective Date: 24.01.2025.</p>
						<p className="text-base mb-6">
							Welcome to JigJoy ("we," "us," or "our"). The following Terms of Use ("Terms") govern your access to and use of
							JigJoy, including any content, functionality, and services offered on or through our website, mobile or desktop
							application, or any other platform referencing these Terms (collectively, the "Services").
						</p>
						<p className="text-base mb-6">
							By accessing or using our Services, you agree to be bound by these Terms and our [Privacy Policy]. If you do not
							agree with these Terms or our Privacy Policy, please do not access or use JigJoy.
						</p>
					</section>

					<section className="mb-8">
						<h2 className="text-2xl font-bold mb-4 text-heading">1. Acceptance of Terms</h2>
						<h3 className="text-xl font-bold mb-3 text-heading">1.1 Binding Agreement</h3>
						<p className="text-base mb-6">
							By registering for an account, downloading our application, or otherwise using our Services, you confirm that you
							accept these Terms. If you do not agree, you must not use our Services.
						</p>

						<h3 className="text-xl font-bold mb-3 text-heading">1.2 Updates to Terms</h3>
						<p className="text-base mb-6">
							We reserve the right to modify these Terms at any time. We will notify you of any material changes by posting the
							new Terms and updating the "Effective Date" at the top. Your continued use of the Services following the posting
							of revised Terms means that you accept and agree to the changes.
						</p>
					</section>

					<section className="mb-8">
						<h2 className="text-2xl font-bold mb-4 text-heading">2. Eligibility</h2>
						<h3 className="text-xl font-bold mb-3 text-heading">2.1 Age Requirement</h3>
						<p className="text-base mb-6">
							You must be at least 16 years of age (or the age of legal majority in your jurisdiction) to use our Services. If
							you are under 16, you may only use JigJoy with permission and direct supervision of a parent or legal guardian.
						</p>

						<h3 className="text-xl font-bold mb-3 text-heading">2.2 Legal Capacity</h3>
						<p className="text-base mb-6">
							By using the Services, you represent and warrant that you have the right, authority, and capacity to enter into
							these Terms and to abide by all of the obligations set forth herein.
						</p>
					</section>

					<section className="mb-8">
						<h2 className="text-2xl font-bold mb-4 text-heading">3. Account Registration</h2>
						<h3 className="text-xl font-bold mb-3 text-heading">3.1 Account Creation</h3>
						<p className="text-base mb-6">
							To access certain features of the Services, you may be required to create a user account ("Account"). You agree
							to provide and maintain true, accurate, and current information, including your email address and payment details
							if you make purchases.
						</p>

						<h3 className="text-xl font-bold mb-3 text-heading">3.2 Account Confidentiality</h3>
						<p className="text-base mb-6">
							You are responsible for maintaining the confidentiality of your Account credentials. You agree to notify us
							immediately of any unauthorized access to or use of your Account. We are not liable for any loss or damage
							arising from your failure to keep your Account secure.
						</p>

						<h3 className="text-xl font-bold mb-3 text-heading">3.3 Restrictions</h3>
						<p className="text-base mb-6">
							You must not create an Account using false identity or information, or on behalf of someone other than yourself
							without their permission. You may not share or transfer any Account.
						</p>
					</section>

					<section className="mb-8">
						<h2 className="text-2xl font-bold mb-4 text-heading">4. Services and Payments</h2>
						<h3 className="text-xl font-bold mb-3 text-heading">4.1 Service Availability</h3>
						<p className="text-base mb-6">
							We make reasonable efforts to ensure that our Services are accessible at all times, but we do not guarantee
							uninterrupted availability. We may temporarily suspend or restrict access to certain features for maintenance or
							upgrades without prior notice.
						</p>

						<h3 className="text-xl font-bold mb-3 text-heading">4.2 Purchases</h3>
						<p className="text-base mb-6">
							If our Services include paid content, subscriptions, or in-app purchases, you may be required to provide
							financial information (e.g., credit or debit card details). You agree to pay all fees and applicable taxes
							incurred by you or anyone using your Account.
						</p>

						<h3 className="text-xl font-bold mb-3 text-heading">4.3 Pricing</h3>
						<p className="text-base mb-6">
							All prices, discounts, and promotions posted on our Services are subject to change without notice. The price
							charged for a product or service will be the price in effect at the time the order is placed.
						</p>

						<h3 className="text-xl font-bold mb-3 text-heading">4.4 Refunds and Cancellation</h3>
						<div className="text-base space-y-4">
							<p>Consumer Right to Cancel</p>

							<p>
								If you are a Consumer and unless an exception applies as detailed below, you have the right to cancel this
								Agreement and return the Product within 14 days without providing a reason. This 14-day period begins on the day
								following the completion of the Transaction. To meet the cancellation deadline, you only need to send us notice
								of your intent to cancel before the 14-day period has expired.
							</p>

							<p>
								To cancel your order, you must inform JigJoy of your decision. For prompt processing, please contact us at
								team@jigjoy.io. Please note that for subscription services, this right to cancel applies only to the initial
								subscription period and not to each automatic renewal.
							</p>

							<p>
								You may also inform us by using the model cancellation form below or by providing a clear, unambiguous statement
								through any of our available communication channels. If you use our online "Contact Us" form, we will
								acknowledge receipt of your cancellation request without delay.
							</p>

							<p>To JigJoy, Buyer Support Team</p>

							<p>I hereby give notice that I cancel my contract of sale of the following Products:</p>

							<p>
								Products:
								<br />
								Ordered on:
								<br />
								Name of consumer(s):
								<br />
								Address of consumer(s):
								<br />
								Signature of consumer(s) (only if this form is submitted on paper):
								<br />
								Date:
							</p>

							<p>Effect of Cancellation</p>

							<p>
								If you cancel this Agreement under the terms above, we will reimburse you for all payments received. We will do
								so without undue delay and no later than 14 days after the day on which you inform us of your decision to
								cancel. We will use the same method of payment you used for the original transaction, and you will not incur any
								fees as a result of this reimbursement.
							</p>

							<p>Exception to the Right to Cancel</p>

							<p>
								Your right as a Consumer to cancel your order does not apply to the supply of Digital Content that you have
								started to download, stream, or otherwise access, nor to any Products from which you have already derived
								benefit.
							</p>

							<p>Refund Policy</p>

							<p>
								Refunds are granted solely at JigJoy's discretion on a case-by-case basis and may be refused. JigJoy will refuse
								a refund request if we find evidence of fraud, refund abuse, or other manipulative behavior that entitles JigJoy
								to counterclaim the refund.
							</p>

							<p>
								This policy does not affect your rights as a Consumer in relation to Products that are not as described, are
								faulty, or are not fit for purpose.
							</p>
						</div>
					</section>

					<section className="mb-8">
						<h2 className="text-2xl font-bold mb-4 text-heading">5. User Conduct</h2>
						<p className="text-base mb-4">By using the Services, you agree not to:</p>
						<ul className="list-disc pl-6 text-base mb-6 space-y-2">
							<li>
								Violate Any Applicable Law or Regulation: You must comply with all applicable laws, regulations, and third-party
								rights.
							</li>
							<li>
								Disrupt the Services: You will not interfere with, disrupt, or attempt to gain unauthorized access to our
								servers, networks, or any part of our Services.
							</li>
							<li>Misrepresent Your Identity: You will not impersonate any person or entity or use false information.</li>
							<li>
								Post Unlawful Content: You must not post or transmit any content that is unlawful, defamatory, harassing,
								abusive, fraudulent, obscene, or otherwise objectionable.
							</li>
							<li>
								Engage in Automated Data Collection: You must not use any robot, spider, scraper, or other automated means to
								access the Services for any purpose without our express written permission.
							</li>
						</ul>
					</section>

					<section className="mb-8">
						<h2 className="text-2xl font-bold mb-4 text-heading">6. Intellectual Property</h2>
						<h3 className="text-xl font-bold mb-3 text-heading">6.1 Ownership</h3>
						<p className="text-base mb-6">
							All content, features, and functionality on JigJoy—including software, text, images, graphics, logos, trademarks,
							service marks, and other material—are owned by JigJoy or its licensors and are protected by copyright, trademark,
							and other intellectual property laws.
						</p>

						<h3 className="text-xl font-bold mb-3 text-heading">6.2 License</h3>
						<p className="text-base mb-6">
							Subject to your compliance with these Terms, we grant you a limited, non-exclusive, non-transferable, and
							revocable license to access and use the Services for your personal, non-commercial purposes.
						</p>

						<h3 className="text-xl font-bold mb-3 text-heading">6.3 Restrictions</h3>
						<p className="text-base mb-6">
							You may not copy, modify, distribute, sell, or lease any part of the Services or reverse engineer or attempt to
							extract the source code of software used in the Services.
						</p>
					</section>

					<section className="mb-8">
						<h2 className="text-2xl font-bold mb-4 text-heading">7. User-Generated Content</h2>
						<h3 className="text-xl font-bold mb-3 text-heading">7.1 Responsibility for Content</h3>
						<p className="text-base mb-6">
							You are solely responsible for any content (including text, photos, or other materials) that you upload, publish,
							or display via our Services.
						</p>

						<h3 className="text-xl font-bold mb-3 text-heading">7.2 License to Us</h3>
						<p className="text-base mb-6">
							By submitting content to the Services, you grant us a worldwide, non-exclusive, royalty-free, transferable,
							sublicensable license to use, reproduce, modify, adapt, publish, and display your content in connection with
							operating and providing our Services.
						</p>

						<h3 className="text-xl font-bold mb-3 text-heading">7.3 Content Removal</h3>
						<p className="text-base mb-6">
							We reserve the right to remove or modify any user-generated content for any reason, including if it violates
							these Terms or is otherwise objectionable.
						</p>
					</section>

					<section className="mb-8">
						<h2 className="text-2xl font-bold mb-4 text-heading">8. Disclaimer of Warranties</h2>
						<p className="text-base mb-6">
							YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, JIGJOY PROVIDES
							THE SERVICES "AS IS" AND "AS AVAILABLE." WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING THE IMPLIED
							WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE DO NOT GUARANTEE THAT
							THE SERVICES WILL BE ERROR-FREE, UNINTERRUPTED, OR FREE OF HARMFUL COMPONENTS.
						</p>
						<p className="text-base mb-6">
							Some jurisdictions do not allow the disclaimer of implied warranties, so the above disclaimers may not apply to
							you.
						</p>
					</section>

					<section className="mb-8">
						<h2 className="text-2xl font-bold mb-4 text-heading">9. Limitation of Liability</h2>
						<p className="text-base mb-6">
							TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, NEITHER JIGJOY NOR ITS DIRECTORS, EMPLOYEES, OR AFFILIATES
							SHALL BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING LOSS OF
							PROFITS, DATA, OR USE, ARISING FROM YOUR USE OF (OR INABILITY TO USE) THE SERVICES. IN NO EVENT SHALL OUR
							AGGREGATE LIABILITY EXCEED THE AMOUNTS PAID BY YOU TO US IN THE SIX (6) MONTHS PRECEDING THE EVENT GIVING RISE TO
							THE CLAIM.
						</p>
						<p className="text-base mb-6">
							Some jurisdictions do not allow the limitation or exclusion of liability for certain damages, so the above
							limitations may not apply to you.
						</p>
					</section>

					<section className="mb-8">
						<h2 className="text-2xl font-bold mb-4 text-heading">10. Indemnification</h2>
						<p className="text-base mb-6">
							You agree to defend, indemnify, and hold harmless JigJoy and its affiliates, directors, officers, employees, and
							agents from and against any claims, liabilities, damages, judgments, awards, losses, or expenses (including
							reasonable attorneys' fees) arising out of or relating to your use of the Services or your breach of these Terms.
						</p>
					</section>

					<section className="mb-8">
						<h2 className="text-2xl font-bold mb-4 text-heading">11. Termination</h2>
						<p className="text-base mb-6">
							We reserve the right to suspend or terminate your access to the Services at any time, with or without notice, if
							we believe you have violated these Terms or if we decide to discontinue providing the Services. Upon termination,
							the rights and licenses granted to you will terminate, and you must cease all use of the Services.
						</p>
					</section>

					<section className="mb-8">
						<h2 className="text-2xl font-bold mb-4 text-heading">12. Governing Law and Dispute Resolution</h2>
						<h3 className="text-xl font-bold mb-3 text-heading">12.1 Governing Law</h3>
						<p className="text-base mb-6">
							These Terms and any dispute arising out of or related to these Terms or the Services will be governed by and
							construed in accordance with the laws of [Insert Applicable Jurisdiction], without regard to its conflict of laws
							principles.
						</p>

						<h3 className="text-xl font-bold mb-3 text-heading">12.2 Venue</h3>
						<p className="text-base mb-6">
							Any legal suit, action, or proceeding arising out of these Terms shall be instituted exclusively in the courts of
							[Insert Venue]. You waive any objections to such jurisdiction or venue.
						</p>

						<h3 className="text-xl font-bold mb-3 text-heading">12.3 EU/UK Provisions (for European Users)</h3>
						<p className="text-base mb-6">
							If you are a resident in the EU or the UK, you may have additional rights and protections under your local
							consumer protection laws. Nothing in these Terms will affect your statutory rights as a consumer.
						</p>
					</section>

					<section className="mb-8">
						<h2 className="text-2xl font-bold mb-4 text-heading">13. Changes to the Services</h2>
						<p className="text-base mb-6">
							We may update and change the features and functionality of JigJoy at any time. We are not liable to you or to any
							third party if we modify, suspend, or discontinue all or any part of the Services.
						</p>
					</section>

					<section className="mb-8">
						<h2 className="text-2xl font-bold mb-4 text-heading">14. Miscellaneous</h2>
						<h3 className="text-xl font-bold mb-3 text-heading">14.1 Entire Agreement</h3>
						<p className="text-base mb-6">
							These Terms and our Privacy Policy constitute the entire agreement between you and JigJoy regarding the Services
							and supersede any prior agreements.
						</p>

						<h3 className="text-xl font-bold mb-3 text-heading">14.2 Severability</h3>
						<p className="text-base mb-6">
							If any provision of these Terms is deemed invalid or unenforceable, that provision will be enforced to the
							maximum extent permissible, and the other provisions will remain in full force and effect.
						</p>

						<h3 className="text-xl font-bold mb-3 text-heading">14.3 No Waiver</h3>
						<p className="text-base mb-6">
							Our failure to exercise or enforce any right or provision of these Terms will not operate as a waiver of that
							right or provision.
						</p>

						<h3 className="text-xl font-bold mb-3 text-heading">14.4 Assignment</h3>
						<p className="text-base mb-6">
							You may not assign or transfer these Terms without our prior written consent. We may freely assign or transfer
							these Terms, in whole or in part, at our sole discretion.
						</p>
					</section>

					<section className="mb-8">
						<h2 className="text-2xl font-bold mb-4 text-heading">15. Contact Us</h2>
						<p className="text-base mb-4">If you have questions about these Terms or our Services, please contact us at:</p>
						<div className="text-base">
							<p>JIG JOY D.O.O.</p>
							<p>Novi Sad, Fruškogorska 1</p>
							<p>Science and Technology Park</p>
							<p>
								<a href="mailto:team@jigjoy.io" className="text-clickable hover:text-hover transition-colors">
									team@jigjoy.io
								</a>
							</p>
						</div>
					</section>

					<section className="mt-12 pt-8 border-t border-outline/20">
						<p className="text-sm text-base/50 text-center">Last Updated: 24.01.2025</p>
					</section>
				</div>
			</div>
		</div>
	)
}

export default Terms
