import React, { useState, useEffect } from "react"
import { motion, AnimatePresence } from "framer-motion"

const BuildingAnimation = () => {
	const [step, setStep] = useState(0)
	const totalSteps = 4
	const [text, setText] = useState("")
	const promptText = "Build a note taking app"
	const [isInView, setIsInView] = useState(false)

	const aiThoughts = [
		"Analyzing request...",
		"Creating app structure with header and sidebar...",
		"Adding note list functionality...",
		"Implementing note editor...",
	]

	useEffect(() => {
		let timer: NodeJS.Timeout
		if (isInView) {
			timer = setInterval(() => {
				setStep((prev) => (prev + 1) % totalSteps)
				setText("")
			}, 3000)
		}
		return () => clearInterval(timer)
	}, [isInView])

	// Typing effect
	useEffect(() => {
		if (step === 0 && isInView) {
			let currentText = ""
			const typeInterval = setInterval(() => {
				if (currentText.length < promptText.length) {
					currentText = promptText.slice(0, currentText.length + 1)
					setText(currentText)
				} else {
					clearInterval(typeInterval)
				}
			}, 50)
			return () => clearInterval(typeInterval)
		}
	}, [step, isInView])

	const componentVariants = {
		hidden: (direction) => ({
			x: direction === "left" ? -100 : direction === "right" ? 100 : 0,
			y: direction === "top" ? -100 : direction === "bottom" ? 100 : 0,
			opacity: 0,
			scale: 0.8,
		}),
		visible: {
			x: 0,
			y: 0,
			opacity: 1,
			scale: 1,
			transition: {
				type: "spring",
				stiffness: 400,
				damping: 25,
			},
		},
	}

	return (
		<motion.div
			className="bg-surface2/30 backdrop-blur-sm p-8 rounded-xl relative overflow-hidden h-[400px]"
			initial={{ opacity: 0 }}
			whileInView={{ opacity: 1 }}
			viewport={{ once: true, margin: "-100px" }}
			onViewportEnter={() => setIsInView(true)}
			onViewportLeave={() => setIsInView(false)}
			transition={{ duration: 0.3 }}
		>
			{/* Chat Interface */}
			<div className="bg-surface1 rounded-lg p-4 mb-4">
				<div className="flex gap-2 mb-3">
					<div className="w-3 h-3 rounded-full bg-red-500"></div>
					<div className="w-3 h-3 rounded-full bg-yellow-500"></div>
					<div className="w-3 h-3 rounded-full bg-green-500"></div>
				</div>
				<div className="flex items-center gap-2">
					<span className="text-accent"></span>
					<div className="font-mono text-sm text-base">
						{text}
						{step === 0 && <span className="animate-pulse">|</span>}
					</div>
				</div>
				{/* AI Thoughts */}
				<AnimatePresence mode="wait">
					{step > 0 && (
						<motion.div
							initial={{ opacity: 0, y: 10 }}
							animate={{ opacity: 1, y: 0 }}
							exit={{ opacity: 0, y: -10 }}
							className="mt-2 text-xs text-base/70 italic"
						>
							<span className="mr-2">🤖</span>
							{aiThoughts[step - 1]}
						</motion.div>
					)}
				</AnimatePresence>
			</div>

			{/* App Assembly Animation */}
			<div className="relative h-48 bg-surface1/50 rounded-lg overflow-hidden">
				<AnimatePresence>
					{step >= 1 && (
						<>
							{/* Header */}
							<motion.div
								custom="top"
								variants={componentVariants}
								initial="hidden"
								animate="visible"
								className="absolute top-0 left-0 right-0 h-10 bg-surface2 border-b border-outline flex items-center px-4"
							>
								<span className="text-sm text-base">My Notes</span>
							</motion.div>

							{/* Sidebar */}
							<motion.div
								custom="left"
								variants={componentVariants}
								initial="hidden"
								animate="visible"
								transition={{ delay: 0.1 }}
								className="absolute top-10 left-0 bottom-0 w-32 bg-surface2 border-r border-outline p-2"
							>
								<div className="text-xs text-base">Notes List</div>
								{step >= 2 && (
									<motion.div
										initial={{ opacity: 0 }}
										animate={{ opacity: 1 }}
										transition={{ delay: 0.15 }}
										className="mt-2 space-y-1"
									>
										<div className="h-2 bg-accent/20 rounded"></div>
										<div className="h-2 bg-accent/20 rounded"></div>
										<div className="h-2 bg-accent/20 rounded"></div>
									</motion.div>
								)}
							</motion.div>

							{/* Main Content */}
							<motion.div
								custom="right"
								variants={componentVariants}
								initial="hidden"
								animate="visible"
								transition={{ delay: 0.2 }}
								className="absolute top-10 left-32 right-0 bottom-0 bg-surface1 p-4"
							>
								{step >= 3 && (
									<motion.div
										initial={{ opacity: 0, y: 20 }}
										animate={{ opacity: 1, y: 0 }}
										transition={{ delay: 0.25 }}
										className="space-y-2"
									>
										<div className="h-4 w-3/4 bg-accent/20 rounded"></div>
										<div className="h-2 w-1/2 bg-accent/20 rounded"></div>
										<div className="h-2 w-2/3 bg-accent/20 rounded"></div>
									</motion.div>
								)}
							</motion.div>
						</>
					)}
				</AnimatePresence>
			</div>
		</motion.div>
	)
}

export default BuildingAnimation
